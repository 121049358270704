/*globals process*/
import React, { useEffect, useState, useCallback } from 'react';
import { CheckIcon, CreatePDFIcon, EmailIcon, SpinnerWhite } from '../Icons';
import Modal from '../Modal';
import QRLogo from '../../images/qr-lotus.png';
import MultiValueInput from '../MultiValueInput';
import { FAB } from '../Button';
import QRCode from 'qrcode.react';
import validator from 'validator';
import { sendEmail } from '../../util/api';
import './styles.scss';

function DownloadPdfButton ({ id, settings }) {
  const [ downloading, setDownloading ] = useState(false);

  const icon = downloading ? <SpinnerWhite /> : <CreatePDFIcon color="#fff" />;

  const handleClick = useCallback(() => {
    setDownloading(true);
  }, []);

  const handleBlur = () => {
    if (downloading) setDownloading(false);
  };

  useEffect(() => {
    if (!downloading) return;

    const timeout = setTimeout(() => setDownloading(false), 30000);

    return () => clearTimeout(timeout);
  }, [ downloading ]);

  const {
    pdfImagesPerPage,
    pdfShowBreaths,
    pdfLayoutView,
    pdfShowSanskrit
  } = settings;

  const downloadUrl = `${process.env.API_ENDPOINT}/sequences/${id}/pdf?ipp=${pdfImagesPerPage}&showSanskrit=${pdfShowSanskrit}&showBreaths=${pdfShowBreaths}&layout=${pdfLayoutView}`;

  return (
    <div className="download-container" >
      <FAB
        handleClick={handleClick}
        onBlur={handleBlur}
        target="_top"
        to={downloadUrl}
        className="download-pdf"
        dataItemId={id}
      >
        {icon}
      </FAB>
      <h4 className="h4">Download PDF</h4>
    </div>
  );
}

const ShareModal = ({
  active,
  onToggle,
  handleToggle,
  hideDownload = true,
  id,
  settings,
  format = 'pdf',
  downloadUrl
}) => {
  const [ inputVal, setInputVal ] = useState('');
  const [ recipients, setRecipients ] = useState([]);
  const [ shareURL ] = useState(
    format === 'pdf' ? downloadUrl :
      `${window.location.origin}/sequences/play/${id}`
  );
  const [ copied, setCopied ] = useState(false);
  const [ shareError, setShareError ] = useState(null);
  const [ emailSending, setEmailSending ] = useState(false);

  let toggleFunction = handleToggle;
  if (onToggle) {
    toggleFunction = onToggle;
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [ copied ]);

  useEffect(() => {
    setShareError(null);
  }, [ inputVal, recipients ]);

  useEffect(() => {
    if (emailSending === 'done') {
      setTimeout(() => {
        setEmailSending(false);
        setRecipients([]);
        setInputVal('');
      }, 3000);
    }
  }, [ emailSending ]);

  const handleCopyClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(shareURL);

      setCopied(true);

      window.dataLayer.push({ event: 'copy-player-url', item_id: id });
    }
  };

  const handleShareClick = () => {
    if (emailSending) return;

    if (!recipients.length && !inputVal) {
      setShareError('A valid email address is required');

      return;
    }

    if (!recipients.length && inputVal && !validator.isEmail(inputVal)) {
      setShareError('A valid email address is required');

      return;
    }

    setEmailSending(true);

    if (recipients.length) {
      recipients.forEach((recipient, i) => {
        sendEmail({ recipient, id, settings, format })
          .then(() => {
            if (i === recipients.length - 1) {
              setEmailSending('done');
            }
          }, (e) => {
            console.error(e);
            setShareError('An error occurred, please try again later');
            setEmailSending(false);
          });
      });
    }

    if (inputVal && validator.isEmail(inputVal)) {
      sendEmail({ recipient: inputVal, id, settings, format })
        .then(() => {
          setEmailSending('done');
        }, (e) => {
          console.error(e);
          setShareError('An error occurred, please try again later');
          setEmailSending(false);
        });
    }
  };

  return (
    <Modal
      title="Share Sequence"
      handleToggle={toggleFunction}
      active={active}
    >
      <div className="share-container modal-content__inner">
        <div className="email-container">
          <MultiValueInput
            placeholder="jane@doe.com"
            emptyPlaceholder="name01@address.com, name02@address.com ..."
            helpText="Please enter each email address separately followed by a comma"
            parentValues={recipients}
            setParentValues={setRecipients}
            label="Recipient's Email"
            inputName="recipient"
            validate={true}
            validateType="email"
            value={inputVal}
            setValue={setInputVal}
            shareError={shareError}
            className="--with-button"
            isPublic={true}
            setTagsArr={() => { return null; }}
          >
            {/*<button
              className="input-button"
              onClick={handleShareClick}
            >
              { emailSending === 'done' ? (
                <CheckIcon />
              ) : emailSending ? (
                <SpinnerWhite />
              ) : <EmailIcon />
              }
            </button>
            */}
            <FAB
              large
              handleClick={handleShareClick}
              disabled={recipients.length === 0}
              className="share-player-email"
              dataItemId={id}
            >
              {
                emailSending === 'done' ? (
                  <CheckIcon />
                ) : emailSending ? (
                  <SpinnerWhite />
                ) : <EmailIcon />
              }
            </FAB>
          </MultiValueInput>
        </div>
        {/*<div className="submit-container text-center">
          <h4 className="h4">Send</h4>
          <FAB
            large
            handleClick={handleShareClick}
          >
            { emailSending === 'done' ? (
              <CheckIcon />
            ) : emailSending ? (
              <SpinnerWhite />
            ) : <EmailIcon />
            }
          </FAB>
        </div>*/}
        <div className="url-container">
          <div className="field text-field --with-button">
            <label htmlFor="shareUrl">
              {
                format === 'pdf' ?
                  'PDF URL' :
                  'Player URL'
              }
            </label>
            <div className="input-container">
              <input
                className={`input ${copied ? 'copied' : ''}`}
                type="text"
                name="shareUrl"
                value={shareURL}
                disabled
              />
              <button
                className="input-button"
                onClick={handleCopyClick}
              >
                {
                  copied ? (
                    <CheckIcon />
                  ) : `Copy Link`
                }
              </button>
            </div>
          </div>
        </div>
        <div className="qr-container">
          <QRCode
            value={shareURL}
            size={100}
            imageSettings={
              {
                src: QRLogo,
                excavate: true,
                height: 10,
                width: 10
              }
            }
          />
          <p className="body">
            {
              format === 'pdf' ?
                'Download sequence pdf with this QR Code' :
                'Open sequence in player with this QR Code'
            }
          </p>
        </div>
        {!hideDownload && <DownloadPdfButton id={id} settings={settings} />}
      </div>
    </Modal>
  );
};

export default ShareModal;
