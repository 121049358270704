/*globals process */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FAB } from '../Button';
import {
  TableShareIcon,
  TableDuplicateIcon,
  TableDeleteIcon,
  SpinnerWhite,
  CheckIcon,
  ChevronDownIcon,
  PlayIcon
} from '../Icons';
import { saveSequence, deleteSequence, getSequence } from '../../util/api';
import { useAsyncError } from '../../common/Errors/use-async-error';
import ShareModal from '../ModalShare';

const CollectionTableRow = ({
  seq,
  setSequences,
  sequences,
  hasPlayerFeature
}) => {
  const [ shareModalActive, setShareModalActive ] = useState(false);
  const [ duplicating, setDuplicating ] = useState(false);
  const [ playing, setPlaying ] = useState(false);
  const [ deleting, setDeleting ] = useState(false);
  const throwAsyncError = useAsyncError();

  useEffect(() => {
    if (duplicating === 'done') {
      setTimeout(() => {
        setDuplicating(false);
      }, 1500);
    }
  }, [ duplicating ]);

  const handleShareModalToggle = () => {
    setShareModalActive(!shareModalActive);
  };

  const {
    sequenceName,
    createdAt,
    id,
    publicSince,
    starts
  } = seq;

  const isPublic = publicSince ? true : false;

  const handleDuplicateSequence = () => {
    if (duplicating) return;

    setDuplicating(true);

    getSequence({ id }).then(s => saveSequence({
      sequence: {
        sequence: s.sequence,
        sequenceName: `${s.sequenceName} (copy)`,
        public: false,
        useSanskrit: s.useSanskrit,
        duration: s.duration,
        starts: 0,
        timerSettings: s.timerSettings,
        avatar: s.avatar,
        tags: s.tags,
        useDefaultText: s.useDefaultText,
        useChime: s.useChime,
        useCueingTTS: s.useCueingTTS,
        useVoiceCueing: s.useVoiceCueing,
        useMomentCueing: s.useMomentCueing,
        pdfShowBreaths: s.pdfShowBreaths,
        pdfShowCueingText: s.pdfShowCueingText,
        ttsSprite: s.ttsSprite
      }
    })).then((newSeq) => {
      setSequences([ ...sequences, newSeq ]);
      setDuplicating('done');
    }, throwAsyncError);
  };

  function PlaySequence () {
    const handleClick = useCallback(() => {
      setPlaying(true);
    }, []);

    return (
      <div className="play-container">
        <FAB
          handleClick={handleClick}
          target="_top"
          tooltip="Play"
          to={`/sequences/play/${id}`}
        >
          <PlayIcon />
        </FAB>
      </div>
    );
  }

  function DownloadPdfButton () {
    const [ downloading, setDownloading ] = useState(false);

    const icon = downloading ? <SpinnerWhite /> : <ChevronDownIcon />;

    const handleClick = useCallback(() => {
      setDownloading(true);
    }, []);

    const handleBlur = () => {
      if (downloading) setDownloading(false);
    };

    useEffect(() => {
      if (!downloading) return;

      const timeout = setTimeout(() => setDownloading(false), 10000);

      return () => clearTimeout(timeout);
    }, [ downloading ]);

    return (
      <div className="download-container">
        <FAB
          handleClick={handleClick}
          onBlur={handleBlur}
          target="_top"
          tooltip="Download PDF"
          to={`${process.env.API_ENDPOINT}/sequences/${id}/pdf`}
          className="download-pdf"
          dataItemId={id}
        >
          {icon}
        </FAB>
      </div>
    );
  }

  const handleDeleteSequence = () => {
    if (deleting) return;

    setDeleting(true);

    deleteSequence({ id }).then(() => {
      let newSequences = [ ...sequences ].filter((a) => a.id !== id);
      setDeleting(false);
      setSequences(newSequences);
    }, throwAsyncError);
  };

  const date = new Date(createdAt);
  const dateOptions = {
    month: "2-digit",
    year: "2-digit",
    day: "2-digit"
  };
  const dateString = date.toLocaleDateString('en-us', dateOptions);

  return (
    <tr>
      <td className="sequence-name text-left">
        <Link to={`/sequences/${encodeURIComponent(id)}`} className="text-link desktop">
          <h2 className="h2">{ sequenceName }</h2>
        </Link>
        <Link to={`/sequences/play/${encodeURIComponent(id)}`} className="text-link mobile">
          <h2 className="h2">{ sequenceName }</h2>
        </Link>
      </td>
      <td className="sequence-visibility">
        <p className="body body--small">{ isPublic ? 'public' : 'private' }</p>
      </td>
      <td className="sequence-starts">
        <p className="body body--small text-center">{ starts ?? 0}</p>
      </td>
      <td className="sequence-date">
        <p className="body body--small text-center">{ dateString }</p>
      </td>
      <td className="actions">
        <div className="sequence-actions">
          {hasPlayerFeature ? ( <div className='action-container mobile-hide'>
            <PlaySequence id={id} />
            <span className="btn-label">Play</span>
          </div> ) : null}
          <div className="action-container">
            <FAB
              handleClick={handleShareModalToggle}
              tooltip="Share Sequence"
            >
              <TableShareIcon />
            </FAB>
            <span className="btn-label">Share</span>
          </div>
          <div className='action-container'>
            <DownloadPdfButton id={id} />
            <span className="btn-label">Download</span>
          </div>
          <div className="action-container">
            <FAB
              handleClick={handleDuplicateSequence}
              tooltip="Duplicate Sequence"
            >
              {
                duplicating === 'done' ? (
                  <CheckIcon
                    style={
                      {
                        width: '1.15rem',
                        height: 'auto'
                      }
                    }
                  />
                ) : duplicating ? (
                  <SpinnerWhite />
                ) : <TableDuplicateIcon />
              }
            </FAB>
            <span className="btn-label">Duplicate</span>
          </div>
          {/* <FAB>
            stats
          </FAB> */}
          <div className="action-container">
            <FAB
              handleClick={handleDeleteSequence}
              tooltip="Delete Sequence"
            >
              {
                deleting ? (
                  <SpinnerWhite />
                ) : <TableDeleteIcon />
              }
            </FAB>
            <span className="btn-label">Delete</span>
          </div>
          <ShareModal
            active={shareModalActive}
            handleToggle={handleShareModalToggle}
            id={id}
            format="player"
          />
        </div>
      </td>
    </tr>
  );
};

export default CollectionTableRow;
