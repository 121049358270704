import { API } from 'aws-amplify';

const DB_NAME = 'sundara-ytl-api';

export const saveSequence = async ({
  id,
  sequence,
  settings
}) => {
  let body;

  if (settings) {
    if (settings.hasChanges) {
      delete settings.hasChanges;
    }

    body = {
      sequence,
      ...settings
    };
  } else {
    body = { ...sequence };
  }

  let res;

  if (id) {
    res = await API.patch(DB_NAME, `/sequences/${id}`, { body });
  } else {
    res = await API.post(DB_NAME, '/sequences', { body });
  }

  if (res.error) {
    throw new Error(`API Error ${res.error}`);
  }

  return res;
};

export const patchSequence = () => {};

export const getSequences = async () => {
  const data = await API.get(DB_NAME, '/sequences');

  return new Promise((resolve, reject) => {
    if (data.error) {
      reject(data);
    } else {
      resolve(data);
    }
  });
};

export const getPublicSequences = async () => {
  const data = await API.get(DB_NAME, '/sequences?public=1');

  return new Promise((resolve, reject) => {
    if (data.error) {
      reject(data);
    } else {
      resolve(data);
    }
  });
};

export const getSequence = async ({ id }) => {
  const data = await API.get(DB_NAME, `/sequences/${id}`);

  return new Promise((resolve, reject) => {
    if (data.error) {
      reject(data);
    } else {
      resolve(data);
    }
  });
};

export const getSequencePlayer = async ({ id }) => {
  const endpoint = await API.endpoint(DB_NAME);

  const res = await fetch(`${endpoint}/sequences/${id}/player`, {
    headers: {
      'Accept': 'application/json'
    }
  });

  if (!res.ok) {
    const data = await res.text();
    throw new Error(`API Error (${res.status}) ${data}`);
  }

  return res.json();
};

export const incrementSequenceStarts = async ({ id }) => {
  const endpoint = await API.endpoint(DB_NAME);

  const res = await fetch(`${endpoint}/sequences/${id}/player`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ starts: 1 })
  });

  if (!res.ok) {
    const data = await res.text();
    throw new Error(`API Error (${res.status}) ${data}`);
  }

  return res.json();
};

export const deleteSequence = async ({ id }) => {
  const data = await API.del(DB_NAME, `/sequences/${id}`);

  return new Promise((resolve, reject) => {
    if (data) {
      reject(data);
    } else {
      resolve({ status: 'OK' });
    }
  });
};

export const getUserProfile = async () => {
  const data = await API.get(DB_NAME, '/users/me');

  return new Promise((resolve, reject) => {
    if (data.error) {
      reject(data);
    } else {
      resolve(data);
    }
  });
};

export const updateUserProfile = async ({ userName, handle }) => {
  const data = await API.patch(DB_NAME, '/users/me', {
    body: {
      name: userName,
      handle
    }
  });

  return new Promise((resolve, reject) => {
    if (data.error) {
      reject(data);
    } else {
      resolve(data);
    }
  });
};

export const changeUserName = async ({ userName }) => {
  const data = await API.patch(DB_NAME, '/users/me', {
    body: {
      name: userName
    }
  });

  return new Promise((resolve, reject) => {
    if (data.error) {
      reject(data);
    } else {
      resolve(data);
    }
  });
};

export const changeUserHandle = async ({ handle }) => {
  const data = await API.patch(DB_NAME, '/users/me', {
    body: {
      handle
    }
  });

  return new Promise((resolve, reject) => {
    if (data.error) {
      reject(data);
    } else {
      resolve(data);
    }
  });
};

export const changeUserImage = async ({ image }) => {
  const data = await API.patch(DB_NAME, '/users/me', {
    body: {
      image
    }
  });

  return new Promise((resolve, reject) => {
    if (data.error) {
      reject(data);
    } else {
      resolve(data);
    }
  });
};

export const sendEmail = async ({ recipient, id, settings, format }) => {
  let options;

  if (format === 'pdf') {
    options = {
      ipp: settings.pdfImagesPerPage,
      layout: settings.pdfLayoutView,
      showSanskrit: settings.pdfShowSanskrit,
      showBreaths: settings.pdfShowBreaths
    };
  }
  const data = API.post(DB_NAME, `/sequences/${id}/share`, {
    body: {
      recipient,
      format,
      options
    }
  });

  return new Promise((resolve, reject) => {
    if (data.error) {
      reject(data);
    } else {
      resolve(data);
    }
  });
};

export const checkoutStripe = async ({ plan, term }) => {
  const data = await API.post(DB_NAME, '/checkout/stripe', {
    body: {
      plan, term,
      successUrl: `${window.location.origin}/checkout/success`,
      cancelUrl: `${window.location.origin}/checkout/cancel`
    }
  });

  if (data.error) throw data.error;
  return data;
};

export const getSubscriptionStatus = async () => {
  const data = await API.get(DB_NAME, '/subscription/status');

  return new Promise((resolve, reject) => {
    if (data.error) return reject(data);
    resolve(data);
  });
};

export const getSubscriptionPortal = async ({ returnUrl }) => {
  const data = await API.get(DB_NAME, '/subscription/portal', {
    queryStringParameters: { returnUrl }
  });

  if (data.error) throw data.error;
  return data;
};

export const getPrismicNav = async () => {
  const refData = await fetch('https://ytl-website.prismic.io/api/v2/')
    .then(res => res.json());

  const ref = refData.refs[0].ref;

  const q = new URLSearchParams({
    ref,
    q: '[[:d = at(document.id, "YTI81xEAACgABlYt")]]'
  });

  const data = await fetch(`https://ytl-website.prismic.io/api/v2/documents/search?${q}`);

  if (data.error) throw data.error;

  return Promise.resolve(data);
};

export const cancelSubscription = async () => {
  let error = null;

  const data = await API.post(DB_NAME, `/subscription/cancel`, {
    body: { reason: "Subscription canceled by user" }
  });

  if (data.error) {
    return Promise.reject(error);
  }

  return Promise.resolve(data);
};

export const resumeSubscription = async () => {
  let error = null;

  const data = await API.post(DB_NAME, `/subscription/resume`, {
    body: { reason: "Subscription resumed by user" }
  });

  if (data.error) {
    return Promise.reject(error);
  }

  return Promise.resolve(data);
};

export const pauseSubscription = async () => {
  let error = null;

  const data = await API.post(DB_NAME, `/subscription/pause`, {
    body: { reason: "User initiated suspension" }
  });

  if (data.error) {
    return Promise.reject(error);
  }

  return Promise.resolve(data);
};

export const getCueingTts = async (voice, texts) => {
  let error = null;

  const data = await API.post(DB_NAME, `/cueing-tts?voice=${encodeURIComponent(voice)}`, {
    body: texts
  });

  if (data.error) {
    return Promise.reject(error);
  }

  return Promise.resolve(data);
};

export const finishPaypalCheckout = async ({ sub_id }) => {
  let error = null;

  const data = await API.post(DB_NAME, '/checkout/paypal', {
    body: {
      paypalSubscriptionId: sub_id
    }
  });

  if (data.error) {
    return Promise.reject(error);
  }

  return Promise.resolve(data);
};

export async function getTTSPreview (text, voice) {
  const res = await API.post(DB_NAME, `/cueing-tts/preview?voice=${encodeURIComponent(voice)}`, {
    redirect: 'manual',
    body: {
      text
    }
  });

  if (res.src) {
    return res;
  }

  throw new Error(res.responseText);
}
