import React, { useMemo } from 'react';
import { SignIn, NewPassword, ResetPassword } from '../Auth';
import { AuthState, ChallengeName } from '@aws-amplify/ui-components';
import Layout from '../Layout';
import './styles.scss';

export const AUTH_SCREENS_AUTH_STATES = new Set([
  AuthState.SignIn,
  ChallengeName.NewPasswordRequired,
  ChallengeName.ResetPassword,
  AuthState.ForgotPassword
]);

export default function AuthScreens ({
  authState,
  authStateOptions,
  handleAuthStateChange,
  user
}) {
  const renderAuth = useMemo(() => {
    switch (authState) {
      case AuthState.SignIn:
        return (
          <SignIn
            authStateOptions={authStateOptions}
            handleAuthStateChange={handleAuthStateChange}
          />
        );
      case ChallengeName.NewPasswordRequired || ChallengeName.ResetPassword:
        return (
          <NewPassword
            user={user}
            handleAuthStateChange={handleAuthStateChange}
          />
        );
      case AuthState.ForgotPassword:
        return (
          <ResetPassword
            user={user}
            handleAuthStateChange={handleAuthStateChange}
          />
        );
      default:
        throw new Error(`Auth state not supported: ${authState}`);
    }
  });

  return (
    <Layout backgroundStyle="collection">
      <div className="start-screen">
        <h1 className="h1 auth-header">Login to <strong>Your Library</strong></h1>
        <div className="auth-container">
          <div className="auth-container__inner">
            {renderAuth}
          </div>
        </div>
      </div>
    </Layout>
  );
}
