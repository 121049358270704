import React, { useState, useEffect, useRef, useCallback } from 'react';
import ValueItem from './value-item';
import validator from 'validator';
import './styles.scss';

const MultiValueInput = ({
  placeholder = '',
  charCodes = [ '\r', ' ', ',', ';' ].map(c => c.codePointAt(0)),
  deleteButton = <span>&times;</span>,
  parentValues,
  setParentValues,
  label = '',
  shouldAddOnBlur = false,
  className = '',
  labelClassName = '',
  validate = false,
  helpText = 'Please enter each tag separately followed by a comma',
  validateType,
  inputName = 'multi-val-text-input',
  value,
  setValue,
  shareError,
  children,
  isPublic,
  setTagsArr,
  emptyPlaceholder: emptyplaceholder,
  ...rest
}) => {
  const inputRef = useRef(null);
  const valueItemsContainerRef = useRef(null);
  const [ values, setValues ] = useState(parentValues);
  const [ error, setError ] = useState('');
  const [ isPaste, setIsPaste ] = useState(false);

  const scrollValueItemsContainerToEnd = useCallback(() => {
    setTimeout(() => {
      const el = valueItemsContainerRef.current;
      el?.scrollBy({ left: el?.scrollWidth, behavior: 'smooth' });
    }, 150);
  }, [ valueItemsContainerRef ]);

  useEffect(() => {
    setParentValues(values);
  }, [ values ]);

  useEffect(() => {
    if (values !== parentValues) {
      setValues(parentValues);
    }
  }, [ parentValues ]);

  const cleanValue = (val) => {
    return (val || '').replace(/\s+/g, ' ').trim();
  };

  const cleanValues = (vals) => {
    const charCodesHasSpace = charCodes.includes(' '.codePointAt(0));
    const r = charCodesHasSpace ? /[,;\s]+/ : /[,;]+/
    return vals.split(r).map(v => cleanValue(v)).filter(v => v);
  };

  const handleItemAdd = (val) => {
    if (values?.includes(val) || !val) {
      setError('Duplicate value');
      setValue('');

      return;
    }

    if (validate && validateType) {
      if (validateType === 'email' && !validator.isEmail(val)) {
        setError('Must be a valid email address');

        return;
      }
    }

    const newVals = values ? [ ...values, cleanValue(val) ] : [ cleanValue(val) ];
    setValues(newVals);

    setTagsArr(newVals);
    scrollValueItemsContainerToEnd();
    setValue('');
  };

  const handleItemRemove = (val) => {
    if (!isPublic) return;

    const newVals = [ ...values ].filter(v => v !== val);

    setValues(newVals);
  };

  const handleKeypress = (e) => {
    if (charCodes.includes(e.charCode)) {
      e.preventDefault();

      handleItemAdd(e.target.value);
      inputRef.current.focus();
    }
  };

  const handleValueChange = (e) => {
    setError('');
    setValue(e.target.value);

    if (isPaste) {
      setIsPaste(false);

      const newValues = cleanValues(e.target.value);

      for (let val of newValuesClean) {
        if (validate && validateType) {
          if (validateType === 'email' && !validator.isEmail(val)) {
            setError('Must be a valid email address');

            return;
          }
        }
      }

      const newVals = [ ...values, ...newValues ];
      setValues(newVals);
      scrollValueItemsContainerToEnd();
      setValue('');
    }
  };

  const handleBlur = (e) => {
    if (shouldAddOnBlur) {
      e.preventDefault();

      handleItemAdd(e.target.value);
      inputRef.current.focus();
    }
  };

  const renderItems = values?.map((v, i) => (
    <ValueItem
      value={v}
      key={`value-item-${i}`}
      deleteButton={deleteButton}
      handleItemRemove={handleItemRemove}
    />
  ));

  const inputAbility = `chips-input-wrapper ${!isPublic ? 'disabled' : 'private'}`;

  return (
    <div className={`multi-value-text-input field text-field ${className}`}>
      <label htmlFor={inputName} className={labelClassName}>
        <span className="label-text">{label}</span>
        <div className={inputAbility}>
          <div className="value-item-container" ref={valueItemsContainerRef}>
            {renderItems}
            <input
              ref={inputRef}
              name={inputName}
              value={value}
              placeholder={values?.length ? '' : placeholder}
              type="text"
              disabled={!isPublic}
              className={`input ${values?.length ? 'value-input-field' : 'no-chips'}`}
              onBlur={handleBlur}
              onKeyPress={handleKeypress}
              onChange={handleValueChange}
              onPaste={() => setIsPaste(true)}
              { ...rest }
            />
          </div>
        </div>
      </label>
      { error ? (
        <div className="errors">
          <p className="body body--small error">{error}</p>
        </div>
      ) : ''}
      { shareError ? (
        <div className="errors">
          <p className="body body--small error">{shareError}</p>
        </div>
      ) : ''}
      { (!error &&!shareError) ? (
        <div className="errors">
          <p className="body body--small">{helpText}</p>
        </div>
      ) : ''}
      {children}
    </div>
  );
};

export default MultiValueInput;
