import React, { useState, useEffect } from 'react';
import { ChevronDownIcon } from '../Icons';
import './styles.scss';

const TH = ({
  activeSortKey,
  sortKey,
  handleClick,
  centered,
  sortDir,
  children
}) => {
  const [ isActive, setIsActive ] = useState(activeSortKey === sortKey);

  useEffect(() => {
    if (isActive && activeSortKey !== sortKey) {
      setIsActive(false);
    } else if (!isActive && activeSortKey === sortKey) {
      setIsActive(true);
    }
  }, [ activeSortKey ]);

  return (
    <th
      onClick={() => handleClick(sortKey)}
      className={`sort-th dir-${sortDir.toLowerCase()}`}
    >
      <p className={`
        body body--small
        ${ centered ? 'text-center' : ''}
        ${ activeSortKey === sortKey ? 'active' : ''}
      `}>
        { children }
        <ChevronDownIcon />
      </p>
    </th>
  );
};

export default TH;
