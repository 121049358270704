/* globals process */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import MarkerSDK from '@marker.io/browser';
import { createRoot } from 'react-dom/client';
import './index.scss';

MarkerSDK.loadWidget({
  destination: process.env.MARKER_IO
}).then((widget) => {
  window.markerWidget = widget;
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
