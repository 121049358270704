import React from 'react';
import { AuthState } from '@aws-amplify/ui-components';
import AuthScreens, { AUTH_SCREENS_AUTH_STATES } from './auth-screens';
import SignedInStartScreen from './signed-in-start-screen';
import './styles.scss';

const StartScreen = ({ authState, ...props }) => {
  if (AUTH_SCREENS_AUTH_STATES.has(authState)) {
    return ( <AuthScreens authState={authState} {...props} /> );
  }

  if (authState === AuthState.SignedIn) {
    return ( <SignedInStartScreen /> );
  }

  throw new Error(`Auth state not supported: ${authState}`);
};

export default StartScreen;
