import React, { useState, useMemo } from 'react';
import { FAB } from '../Button';
import { PlusIcon } from '../Icons';
import CollectionTableRow from './table-row';
import { useSubscription } from '../ProtectedRoute';
import TH from '../TableHeader';

const CollectionList = ({ sequences, setSequences }) => {
  const subscription = useSubscription();
  const [ sortBy, setSortBy ] = useState('name');
  const [ sortDir, setSortDir ] = useState('ASC');

  const hasPlayerFeature = useMemo(() => {
    return subscription?.planFeatures?.includes('player') ?? false;
  }, [ subscription ]);

  let sortableSequences = [ ...sequences ];

  const listItems = sortableSequences.sort((a, b) => {
    if (sortBy === 'name') {
      if (sortDir === 'ASC') {
        if (a.sequenceName < b.sequenceName) return -1;
        if (a.sequenceName > b.sequenceName) return 1;
      } else if (sortDir === 'DESC') {
        if (a.sequenceName > b.sequenceName) return -1;
        if (a.sequenceName < b.sequenceName) return 1;
      }
    }

    if (sortBy === 'duration') {
      if (sortDir === 'ASC') {
        if (a.duration < b.duration) return -1;
        if (a.duration > b.duration) return 1;
      } else if (sortDir === 'DESC') {
        if (a.duration < b.duration) return 1;
        if (a.duration > b.duration) return -1;
      }
    }

    if (sortBy === 'visibility') {
      if (sortDir === 'ASC') {
        if (a.publicSince && !b.publicSince) return -1;
      } else if (sortDir === 'DESC') {
        if (!a.publicSince && b.publicSince) return -1;
      }
    }

    if (sortBy === 'activities') {
      if (sortDir === 'ASC') {
        if (a.sequence.length < b.sequence.length) return -1;
        if (a.sequence.length > b.sequence.length) return 1;
      } else if (sortDir === 'DESC') {
        if (a.sequence.length < b.sequence.length) return 1;
        if (a.sequence.length > b.sequence.length) return -1;
      }
    }

    if (sortBy === 'starts') {
      if (sortDir === 'ASC') {
        if (a.starts < b.starts) return -1;
        if (a.starts > b.starts) return 1;
      } else if (sortDir === 'DESC') {
        if (a.starts < b.starts) return 1;
        if (a.starts > b.starts) return -1;
      }
    }

    if (sortBy === 'date') {
      if (sortDir === 'ASC') {
        if (a.createdAt < b.createdAt) return -1;
        if (a.createdAt > b.createdAt) return 1;
      } else if (sortDir === 'DESC') {
        if (a.createdAt < b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
      }
    }

    return 0;
  }).map((seq, i) => {
    if (seq.deletedAt) return;

    return (
      <CollectionTableRow
        seq={seq}
        key={`tablerow-${i}`}
        setSequences={setSequences}
        sequences={sequences}
        hasPlayerFeature={hasPlayerFeature}
      />
    );
  });

  const handleHeaderClick = (key) => {
    if (key === sortBy) {
      if (sortDir === 'ASC') {
        setSortDir('DESC');
      } else {
        setSortDir('ASC');
      }
    } else {
      setSortBy(key);
      setSortDir('ASC');
    }
  };

  return (
    <div className="collection-list">
      <div className="collection-list__inner">
        <div className="title-container text-center">
          <h1 className="h1">My Sequences</h1>
          <h3 className="h3 create-text">
            <FAB
              to={'/sequences'}
            >
              <PlusIcon />
            </FAB>
            Create a new sequence
          </h3>
        </div>
        <div className="list-container">
          <table className="sequences-table">
            <thead>
              <tr>
                <TH
                  activeSortKey={sortBy}
                  sortDir={sortDir}
                  sortKey="name"
                  handleClick={handleHeaderClick}
                >
                  Name
                </TH>
                {/* <TH
                  activeSortKey={sortBy}
                  sortDir={sortDir}
                  sortKey="duration"
                  handleClick={handleHeaderClick}
                  centered
                >
                  Duration
                </TH>
                <TH
                  activeSortKey={sortBy}
                  sortDir={sortDir}
                  sortKey="activities"
                  handleClick={handleHeaderClick}
                  centered
                >
                  Activities
                </TH> */}
                {/* <TH
                  activeSortKey={sortBy}
                  sortDir={sortDir}
                  sortKey="difficulty"
                  handleClick={handleHeaderClick}
                >
                  Difficulty
                </TH> */}
                <TH
                  activeSortKey={sortBy}
                  sortDir={sortDir}
                  sortKey="visibility"
                  handleClick={handleHeaderClick}
                  centered
                >
                  Visibility
                </TH>
                <TH
                  activeSortKey={sortBy}
                  sortDir={sortDir}
                  sortKey="starts"
                  handleClick={handleHeaderClick}
                  centered={true}
                >
                  Starts
                </TH>
                <TH
                  activeSortKey={sortBy}
                  sortDir={sortDir}
                  sortKey="date"
                  handleClick={handleHeaderClick}
                  centered={true}
                >
                  Date
                </TH>
                <th>
                  <p className="body body--small">Actions</p>
                </th>
              </tr>
            </thead>
            <tbody>
              { listItems }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CollectionList;
