import React from 'react';

const ValueItem = ({
  value,
  deleteButton,
  handleItemRemove
}) => (
  <div className="value-item">
    <span className="value">{value}</span>
    <div className="delete-button" onClick={() => handleItemRemove(value)}>
      { deleteButton }
    </div>
  </div>
);

export default ValueItem;