/*globals process*/
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../Layout';
import { TextButton } from '../Button';
import { Spinner } from '../Icons';
import { useSubscription } from '../ProtectedRoute/check-subscription';
import { getSubscriptionPortal, getUserProfile } from '../../util/api';

export default function SignedInStartScreen () {
  const appHistory = useHistory();
  const subscription = useSubscription({ force: true });

  useEffect(() => {
    if (subscription?.active) {
      appHistory.replace('/my-collection');
    }
  }, [ subscription, appHistory ]);

  async function gotoMyAccount (ev) {
    ev?.preventDefault();

    const userProfile = await getUserProfile();
    const isPaypal = !!userProfile.paypalSubscriptionId;

    if (isPaypal) {
      window.location.href = '/billing';
      return;
    }

    const portalSession = await getSubscriptionPortal({ returnUrl: window.location.href });
    window.location = portalSession.url;
  }

  let body = (
    <div className="start-screen">
      <Spinner />
    </div>
  );

  if (!subscription) {
    body = (
      <div className="start-screen">
        <h1 className="h1 auth-header">Loading pose catalog...</h1>
        <Spinner />
      </div>
    );
  } else if (!subscription?.active && subscription?.status === 'paused') {
    body = (
      <div className="start-screen">
        <h1 className="h1 auth-header">Subscription is paused</h1>
        <div className="auth-container">
          <div className="auth-container__inner invalid-subscription">
            <TextButton filled handleClick={gotoMyAccount}>
              My Account
            </TextButton>
          </div>
        </div>
      </div>
    );
  } else if (!subscription?.active) {
    body = (
      <div className="start-screen">
        <h1 className="h1 auth-header">Subscription is paused or cancelled</h1>
        <div className="auth-container">
          <div className="auth-container__inner invalid-subscription">
            <TextButton filled handleClick={gotoMyAccount}>
              My Account
            </TextButton>
            <a
              className="text-button button filled"
              href={`${process.env.LANDING_PAGE_DOMAIN}/subscriptions/`}
            >
              Subscribe
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Layout backgroundStyle="collection">
      {body}
    </Layout>
  );
}
