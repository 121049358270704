import React from 'react';
import GettingStartedImg1 from '../../images/getting-started-1.png';
import GettingStartedImg2 from '../../images/getting-started-2.png';
import GettingStartedImg3 from '../../images/getting-started-3.png';
import { ChevronRightIcon } from '../Icons';
import { FAB } from '../Button';

const GetStarted = () => {
  return (
    <div className="my-collection text-center">
      <h1 className="h1 create-header">My Collection</h1>
      <div className="pose-examples">
        <div className="pose">
          <img className="img-responsive" src={GettingStartedImg1} />
        </div>
        <div className="pose">
          <img className="img-responsive" src={GettingStartedImg2} />
        </div>
        <div className="pose">
          <img className="img-responsive" src={GettingStartedImg3} />
        </div>
      </div>
      <div className="create-body-text">
        <h2 className="h2">Create your first sequence</h2>
        <p className="body">Create a yoga sequence featuring poses, activities, messages, and music for yourself and your students</p>
      </div>
      <div className="create-cta">
        <h2 className="h2 bold dk-green">Let’s Get Started!</h2>
        <FAB
          to={'/sequences'}
          large={true}
        >
          <ChevronRightIcon />
        </FAB>
      </div>
    </div>
  );
};

export default GetStarted;
