/* eslint react/display-name: "off" */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import checkSubscription from './check-subscription';

export { useSubscription } from './check-subscription';

export default function protectedRoute (Comp, route = '/') {
  return (props) => {
    const appHistory = useHistory();

    async function checkAuthState () {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (err) {
        appHistory.push(route);
      }
    }

    useEffect(() => {
      checkAuthState();
    }, []);

    return <Comp {...props} />;
  };
}

export function protectedSubscriptionRoute (Comp, route = '/') {
  return (props) => {
    const appHistory = useHistory();

    async function checkState () {
      try {
        await Auth.currentAuthenticatedUser();
      } catch (err) {
        return appHistory.push(route);
      }

      try {
        const subscription = await checkSubscription();

        if (!subscription.active) return appHistory.push(route);
      } catch (err) {
        console.error(err);

        return appHistory.push(route);
      }
    }

    useEffect(() => { checkState(); }, []);

    return <Comp {...props} />;
  };
}
