/* eslint-disable react/jsx-max-depth */
/* global process */
import React, { useReducer, useEffect, useCallback, Suspense, useState } from 'react';
import { Spinner, SpinnerWhite } from './Icons';
import ErrorBoundary from './ErrorBoundary';
import ErrorNotification from './ErrorNotification';
import SidebarProvider from '../common/Sidebar/SidebarProvider';
import PreviewProvider from '../common/SequencePreview/PreviewProvider';
import ProfileImageProvider from '../common/ProfileImage/profile-image-provider';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from 'react-router-dom';
import Amplify, { Auth, Storage as AWSStorage } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';
import MxMS3Provider from '../amplify/mxm-s3-provider';
import StartScreen from './StartScreen';
import Header, { gotoYourAccount, NavigationPromptContextProvider } from './Header';
import MyCollection from './MyCollection';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import { useAsyncError } from '../common/Errors/use-async-error';
import { APIErrorProvider } from '../common/Errors/api-error-provider';
import multiBackendCreator from '../common/react-dnd-multi-backend';
// import SequenceBuilderV2 from './sequence-builder';

const PublicSequences = React.lazy(() => import('./PublicSequences'));
// const SequenceBuilder = React.lazy(() => import('./SequenceBuilder'));
const SequenceBuilderV2 = React.lazy(() => import('./sequence-builder'));
const UserProfile = React.lazy(() => import('./UserProfile'));
const StudentView = React.lazy(() => import('./StudentView'));
const CheckoutReturn = React.lazy(() => import('./stripe/checkout-return'));
const SequencePDF = React.lazy(() => import('./SequencePDF'));
const SignUp = React.lazy(() => import('./Auth/sign-up'));
const VerifyEmail = React.lazy(() => import('./Auth/verify-email'));
const PaymentType = React.lazy(() => import('./Auth/payment-type'));
const ChangeSubscription = React.lazy(() => import('./Auth/change-sub'));

AWSStorage.addPluggable(new MxMS3Provider());

const amplifyConfigs = {};

if (process.env.COOKIE_DOMAIN) {
  amplifyConfigs.cookieStorage = {
    domain: process.env.COOKIE_DOMAIN
  };
}

Amplify.configure({
  ...amplifyConfigs,
  'aws_cognito_region': 'us-east-1',
  'aws_user_pools_id': process.env.AWS_USER_POOLS_ID,
  'aws_user_pools_web_client_id': process.env.AWS_USER_POOLS_WEB_CLIENT_ID,
  'aws_cognito_identity_pool_id': process.env.AWS_COGNITO_IDENTITY_POOL_ID,
  'aws_mandatory_sign_in': true,
  API: {
    endpoints: [
      {
        name: "sundara-ytl-api",
        endpoint: process.env.API_ENDPOINT,
        'custom_header': async () => {
          let token = (await Auth.currentSession()).getIdToken().getJwtToken();
          return { Authorization: `Bearer ${token}` };
        }
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: process.env.USER_ASSETS_BUCKET,
      region: 'us-east-1'
    },
    MxMS3: {
      bucket: process.env.USER_ASSETS_BUCKET_ENDPOINT,
      endpoint: process.env.USER_ASSETS_BUCKET_ENDPOINT,
      region: 'us-east-1',
      s3BucketEndpoint: true
    }
  }
});

function SignOutComponent ({ handleAuthStateChange }) {
  const throwAsyncError = useAsyncError();

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (e) {
      console.error('error when logging out: ', e);
      throw e;
    }

    sessionStorage.clear();

    handleAuthStateChange(AuthState.SignedOut);
    window.location.href = '/';
  };

  const logOut = () => {
    signOut().catch(throwAsyncError);
  };

  setTimeout(logOut, 100);

  return (
    <div className="layout please-wait">
      <Spinner />
      <p>Please wait...</p>
    </div>
  );
}

function YourAccountComponent () {
  const appHistory = useHistory();

  useEffect(() => {
    let stripePortalRedirect = sessionStorage.getItem('stripePortalRedirect');

    if (appHistory.action === 'POP' && stripePortalRedirect) {
      sessionStorage.removeItem('stripePortalRedirect');
      appHistory.push('/');
    } else {
      sessionStorage.setItem('stripePortalRedirect', appHistory.length);
      gotoYourAccount(null, document.referrer || window.location.origin);
    }
  }, []);

  return (
    <div className="layout please-wait">
      <Spinner />
      <p>Please wait...</p>
    </div>
  );
}

// function isTouch () {
//   try {
//     const mediaQuery = window.matchMedia("(any-pointer: fine)");

//     console.debug('Touch debugging: ' + JSON.stringify({
//       matches: mediaQuery.matches,
//       maxTouchPoints: navigator.maxTouchPoints,
//       msMaxTouchPoints: navigator.msMaxTouchPoints,
//       ontouchstart: ( 'ontouchstart' in window )
//     }));

//     return !mediaQuery.matches &&
//       ( navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0 );
//   } catch (e) {
//     return ( 'ontouchstart' in window ) ||
//       ( navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0 );
//   }
// }

function AppContextProviders ({ children }) {
  // const wasTouch = isTouch();

  // useEffect(() => {
  //   try {
  //     const handleTouchChange = (ev) => {
  //       if (isTouch() === wasTouch) return;

  //       console.warn('Touch has changed!' + JSON.stringify({
  //         wasTouch,
  //         matches: ev.currentTarget.matches,
  //         maxTouchPoints: navigator.maxTouchPoints,
  //         msMaxTouchPoints: navigator.msMaxTouchPoints,
  //         ontouchstart: ( 'ontouchstart' in window )
  //       }));

  //       window.location.reload();
  //     };

  //     const mediaQuery = window.matchMedia("(any-pointer: fine)");
  //     // mediaQuery.addListener(handleTouchChange);
  //     mediaQuery.addEventListener('change', handleTouchChange);

  //     return () => {
  //       // mediaQuery.removeListener(handleTouchChange);
  //       mediaQuery.removeEventListener('change', handleTouchChange);
  //     };
  //   } catch (e) {
  //     // nothing to do
  //   }
  // }, []);

  return (
    <APIErrorProvider>
      <SidebarProvider>
        <PreviewProvider>
          <ProfileImageProvider>
            <DndProvider
              // backend={wasTouch ? TouchBackend : HTML5Backend}
              backend={multiBackendCreator(TouchBackend, HTML5Backend)}
              options={{ touchSlop: 45 }}
            >
              <NavigationPromptContextProvider>
                {children}
              </NavigationPromptContextProvider>
            </DndProvider>
          </ProfileImageProvider>
        </PreviewProvider>
      </SidebarProvider>
    </APIErrorProvider>
  );
}

function App () {
  const [ auth, setAuth ] = useReducer((state, updates) => {
    const newState = { ...state, ...updates };

    if (!newState.authStateOptions) newState.authStateOptions = {};

    if (updates.user?.username) {
      window.dataLayer.push({ user_id: updates.user.username });

      window.pendo.initialize({
        visitor: {
          id: updates.user.username,
          email: updates.user.attributes.email
        },
        account: {
          id: updates.user.username
        }
      });
    }

    return newState;
  }, {});

  useEffect(() => {
    Auth.currentUserInfo().then((userRes) => {
      if (userRes) {
        setAuth({ user: userRes, authState: AuthState.SignedIn });
      } else if (window.location.pathname === '/reset-password') {
        setAuth({ authState: AuthState.ForgotPassword });
      } else {
        setAuth({ authState: AuthState.SignIn });
      }
    });
  }, []);

  const handleAuthStateChange = useCallback((state, userRes, options={}) => {
    if (state === AuthState.SignedOut) {
      state = AuthState.SignIn;
    }

    setAuth({ user: userRes, authState: state, authStateOptions: options });
  });

  return (
    <AppContextProviders>
      <div className="App">
        <Router
          getUserConfirmation={(message, callback) => {
            const allowTransition = window.confirm(message);
            callback(allowTransition);
          }}
        >
          <Header
            authState={auth.authState}
            handleAuthStateChange={handleAuthStateChange}
          />
          <Switch>
            <Route exact path="/sign-up">
              <ErrorBoundary>
                <Suspense fallback={<SpinnerWhite />}>
                  <SignUp
                    authState={auth.authState}
                    authStateOptions={auth.authStateOptions}
                    handleAuthStateChange={handleAuthStateChange}
                  />
                </Suspense>
              </ErrorBoundary>
            </Route>
            <Route exact path="/sign-up/:plan/:term">
              <ErrorBoundary>
                <Suspense fallback={<SpinnerWhite />}>
                  <SignUp
                    authState={auth.authState}
                    authStateOptions={auth.authStateOptions}
                    handleAuthStateChange={handleAuthStateChange}
                  />
                </Suspense>
              </ErrorBoundary>
            </Route>
            <Route exact path="/verify-email">
              <ErrorBoundary>
                <Suspense fallback={<SpinnerWhite />}>
                  <VerifyEmail
                    authState={auth.authState}
                    handleAuthStateChange={handleAuthStateChange}
                    user={auth.user}
                  />
                </Suspense>
              </ErrorBoundary>
            </Route>
            <Route exact path="/verify-email/:code">
              <ErrorBoundary>
                <Suspense fallback={<SpinnerWhite />}>
                  <VerifyEmail
                    authState={auth.authState}
                    handleAuthStateChange={handleAuthStateChange}
                    user={auth.user}
                  />
                </Suspense>
              </ErrorBoundary>
            </Route>
            <Route exact path="/checkout/success">
              <ErrorBoundary>
                <Suspense fallback={<SpinnerWhite />}>
                  <CheckoutReturn />
                </Suspense>
              </ErrorBoundary>
            </Route>
            <Route exact path="/checkout/cancel">
              <ErrorBoundary>
                <Suspense fallback={<SpinnerWhite />}>
                  <CheckoutReturn />
                </Suspense>
              </ErrorBoundary>
            </Route>
            <Route exact path="/">
              <ErrorBoundary>
                { auth.authState ? (
                  <StartScreen
                    authState={auth.authState}
                    handleAuthStateChange={handleAuthStateChange}
                    authStateOptions={auth.authStateOptions}
                    user={auth.user}
                  />
                ) : null }
              </ErrorBoundary>
            </Route>
            <Route exact path="/user">
              <ErrorBoundary>
                <Suspense fallback={<SpinnerWhite />}>
                  <UserProfile />
                </Suspense>
              </ErrorBoundary>
            </Route>
            <Route exact path="/my-collection">
              <ErrorBoundary>
                <MyCollection />
              </ErrorBoundary>
            </Route>
            <Route exact path="/public-sequences">
              <ErrorBoundary>
                <Suspense fallback={<SpinnerWhite />}>
                  <PublicSequences />
                </Suspense>
              </ErrorBoundary>
            </Route>
            <Route exact path="/sequences">
              <ErrorBoundary>
                <Suspense fallback={<SpinnerWhite />}>
                  <SequenceBuilderV2 />
                </Suspense>
              </ErrorBoundary>
            </Route>
            <Route exact path="/sequences/:id">
              <ErrorBoundary>
                <Suspense fallback={<SpinnerWhite />}>
                  <SequenceBuilderV2 />
                </Suspense>
              </ErrorBoundary>
            </Route>
            <Route exact path="/sequences/play/:id">
              <ErrorBoundary>
                <Suspense fallback={<SpinnerWhite />}>
                  <StudentView />
                </Suspense>
              </ErrorBoundary>
            </Route>
            <Route exact path="/sequences/:id/pdf">
              <ErrorBoundary>
                <Suspense fallback={<SpinnerWhite />}>
                  <SequencePDF />
                </Suspense>
              </ErrorBoundary>
            </Route>
            <Route exact path="/account">
              <ErrorBoundary>
                <YourAccountComponent />
              </ErrorBoundary>
            </Route>
            <Route exact path="/sign-out">
              <ErrorBoundary>
                <SignOutComponent handleAuthStateChange={handleAuthStateChange} />
              </ErrorBoundary>
            </Route>
            <Route exact path="/payment-type">
              <ErrorBoundary>
                <PaymentType
                  authState={auth.authState}
                  handleAuthStateChange={handleAuthStateChange}
                  user={auth.user}
                />
              </ErrorBoundary>
            </Route>
            <Route exact path="/billing">
              <ErrorBoundary>
                <ChangeSubscription
                  user={auth.user}
                  authState={auth.authState}
                  handleAuthStateChange={handleAuthStateChange}
                />
              </ErrorBoundary>
            </Route>
            <Redirect from="*" to={{ pathname: '/' }} />
          </Switch>
        </Router>
        <ErrorNotification />
      </div>
    </AppContextProviders>
  );
}

export default App;
