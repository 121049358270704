import { useState, useEffect } from 'react';
import { getSubscriptionStatus } from '../../util/api';

const ERROR_GRACE_PERIOD = 21600000; // 1 hour
const ACTIVE_RECHECK_INTERVAL = 7200000; // 2 hours
const OTHER_RECHECK_INTERVAL = 300000; // 5 minutes

function getCurrent () {
  let current = sessionStorage.getItem('subscription');

  if (current) {
    current = JSON.parse(current);
    current.timestampMs = Date.parse(current?.timestamp ?? 0);
    //TODO validate signature
  }

  return current ?? null;
}

export default async function checkSubscription ({ force }={}) {
  const current = getCurrent();
  const currentDelta = Date.now() - (current?.timestampMs ?? 0);

  if (
    !force && (
      ( current?.active && currentDelta > 0 && currentDelta < ACTIVE_RECHECK_INTERVAL ) ||
      ( !current?.active && currentDelta > 0 && currentDelta < OTHER_RECHECK_INTERVAL )
    )
  ) {
    return current;
  }

  let result;

  try {
    result = await getSubscriptionStatus();
  } catch (e) {
    console.error(e);

    if (currentDelta > 0 && currentDelta < ERROR_GRACE_PERIOD) return current;

    result = {
      ...current,
      active: false,
      status: 'error'
    };
  }

  sessionStorage.setItem('subscription', JSON.stringify(result));

  window.dataLayer.push({
    subscription: result.active || false
  });

  return result;
}

export function useSubscription ({ force }={}) {
  const [ subscription, setSubscription ] = useState(null);

  useEffect(() => {
    checkSubscription({ force }).then(setSubscription);
  }, []);

  return subscription;
}

export function forgetSubscription () {
  sessionStorage.removeItem('subscription');
}
