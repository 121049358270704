import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import GetStarted from './get-started';
import BrowserTooSmall from '../BrowserTooSmall';
import { Spinner } from '../Icons';
import CollectionList from './collection-list';
import { getSequences } from '../../util/api';
import { useAsyncError } from '../../common/Errors/use-async-error';
import { protectedSubscriptionRoute } from '../ProtectedRoute';
import './styles.scss';

const MyCollection = () => {
  const [ loading, setLoading ] = useState(true);
  const [ sequences, setSequences ] = useState(false);
  const throwAsyncError = useAsyncError();

  useEffect(() => {
    getSequences().then(setSequences, throwAsyncError);
  }, []);

  useEffect(() => {
    if (Array.isArray(sequences)) {
      sessionStorage.removeItem('stripePortalRedirect');
      setLoading(false);
    }
  }, [ sequences ]);

  return (
    <Layout
      backgroundStyle={!sequences.length ? "collection" : ""}
    >
      {
        loading ? (
          <Spinner />
        ) : sequences.length ? (
          <CollectionList
            sequences={sequences}
            setSequences={setSequences}
          />
        ) : (
          <GetStarted />
        )
      }
    </Layout>
  );
};

export default protectedSubscriptionRoute(MyCollection);
