import React, { useMemo } from 'react';
import { Modal as MuiModal } from '@material-ui/core';
import { FAB } from '../Button';
import { CloseIcon } from '../Icons';
import { uuidv4 } from '../../util/uuid';
import './styles.scss';

// const Modal = ({
//   title,
//   handleToggle,
//   children,
//   active
// }) => (
//   <div className={`modal ${active ? `active` : ``}`}>
//     <div className="modal__inner">
//       <div className="modal-title-container">
//         <h1 className="h1">{title}</h1>
//         <FAB
//           handleClick={handleToggle}
//         >
//           <CloseIcon />
//         </FAB>
//       </div>
//       <div className="modal-content">
//         {children}
//       </div>
//     </div>
//   </div>
// );
//
// export default Modal;

export default function Modal ({
  id,
  title,
  handleToggle,
  children,
  active
}) {
  if (!id) {
    id = uuidv4().replace(/[^a-f0-9]/ig, '').slice(0, 10);
  }

  const titleId = `modal-${id}-title`;

  const titleRendered = useMemo(() => (
    <header className="modal-title">
      <h1 className="h1" id={titleId}>{title}</h1>
      <FAB handleClick={handleToggle}>
        <CloseIcon />
      </FAB>
    </header>
  ), [ title, handleToggle ]);

  const bodyRendered = useMemo(() => (
    <section className="modal-content">
      {children}
    </section>
  ), [ children ]);

  return (
    <MuiModal
      open={active}
      onClose={handleToggle}
      aria-labelledby={titleId}
      BackdropProps={{ className: 'MuiBackdrop-root' }}
    >
      <div className="ytl-modal">
        {titleRendered}
        {bodyRendered}
      </div>
    </MuiModal>
  );
}
