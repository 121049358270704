import React, { useEffect, useState } from 'react';

function waitForZeReady (callback) {
  if ('function' === typeof window.zE && 'function' === typeof window.zE.activate) {
    return callback();
  }

  setTimeout(() => waitForZeReady(callback), 100);
}

export function lazyLoadZendesk (callback) {
  const zdscript = document.createElement('script');
  zdscript.setAttribute('id', 'ze-snippet');
  zdscript.setAttribute('async', 'async');
  zdscript.src = `https://static.zdassets.com/ekr/snippet.js?key=47cbab27-08d6-47b6-be7e-9d0afe4d6c30`;
  (document.getElementsByTagName('body')[0]).appendChild(zdscript);

  zdscript.addEventListener('load', () => {
    if (callback) return waitForZeReady(callback);
  });
}
